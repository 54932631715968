import { Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserDataService from '../services/user.service'
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../App";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const NewPass = () => {
    const context = useContext(MyContext);
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const { token } = useParams();
    const [data, setData] = useState(null);


    useEffect(() => {
        const checkCode = async () => {
            setIsLoading(true); // Activa el estado de carga
            try {
                var data = {
                    token: token
                };
                const response = await UserDataService.getCheckCode(data); // Cambia por tu API
                setData(response);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false); // Desactiva el estado de carga
            }
        };
        checkCode();
    }, []);

    const [formfields, setFormfields] = useState({
        password: "",
        password_confirmed: "",
    });

    const onchangeInput = (e) => {
        setFormfields(() => ({
            ...formfields,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formfields.password === "") {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "La contraseña no puede quedar en blanco!",
            });
            return false;
        }

        if (formfields.password_confirmed === "") {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "La contraseña de confirmación no puede quedar en blanco!",
            });
            return false;
        }

        if (formfields.password !== formfields.password_confirmed) {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "Las contraseñas no coinciden!",
            });
            return false;
        }

        var data = {
            token: token,
            password: formfields.password
        };
        setIsLoading(true);
        UserDataService.getChangePass(data).then(response => {
            if (response.data.code === 200) {
                context.setAlertBox({
                    open: true,
                    error: false,
                    msg: "Contraseña reseteada con éxito",
                });
                setTimeout(() => {
                    setIsSuccessful(true);
                    setIsLoading(false);
                    history("/signIn");
                }, 2000);
            } else {
                setIsLoading(false);
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: response.data.message,
                });
            }
        })
            .catch(ex => {
                setIsLoading(false);
                console.log(ex);
            });
    };

    return (
        <main>
            <section class=" my-8 my-lg-14">
                <div class="container">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-xl-4 col-lg-6 col-md-12 m-auto">
                            <div>
                                {isLoading ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "50vh",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                ) : data ? (
                                    data?.data.code === 200 ? (
                                        <>
                                            <div class="mb-lg-9 mb-4">
                                                <h1 class="mb-2 h2 fw-bold" style={{ textAlign: 'center' }}>Cambio de clave</h1>
                                                <p>Crea una nueva contraseña</p>
                                            </div>
                                            <form class="needs-validation" novalidate onSubmit={handleSubmit}>
                                                <div class="form-group">
                                                    <input type="password" class="form-control" required name="password" placeholder="Contraseña *" onChange={onchangeInput} />
                                                </div>
                                                <br />
                                                <div class="form-group">
                                                    <input type="password" class="form-control" required name="password_confirmed" placeholder="Confirma tu contraseña *" onChange={onchangeInput} />
                                                </div>
                                                <br />
                                                <div class="row g-3">
                                                    <div class="col-12 d-grid gap-2">
                                                        <Button type="submit" class="btn btn-primary btntv">{isLoading === true ? <CircularProgress /> : "Restablecer contraseña"}</Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    ) : (
                                        <div class="mb-lg-9 mb-5">
                                            <h1 class="mb-2 h3 fw-bold" style={{ textAlign: 'center' }}>Enlace incorrecto!</h1><br />
                                            <p>Estimad@ usuario, el enlance para cambio de clave es incorrecto, por favor vuelva a intentar.</p><br />
                                            <div class="col-12 d-grid gap-2">
                                                <Link to={"/signIn"} class="btn btn-light">Atrás</Link>
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <p style={{ textAlign: 'center' }}>No hay datos disponibles.</p>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )

}

export default NewPass;