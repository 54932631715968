import { useNavigate } from "react-router-dom";
import OrdenDataService from "../services/order.service";
import ConfigDataService from "../services/config.service";
import React, { useState, useEffect } from "react";
import { FormatoNumero, FormatearFecha } from "../utils/Utility";
import { Pagination } from "@mui/material";


const Orders = () => {

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [orders, setOrders] = useState([]);
    const [products, setproducts] = useState([]);
    const [isLogin, setIsLogin] = useState(false);
    const history = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);

        const token = localStorage.getItem("authToken");
        if(token!=="" && token!==undefined  && token!==null){
          setIsLogin(true);
        }
        else{
          history("/signIn");
        }

        const user = JSON.parse(localStorage.getItem("user"));

        var data = {
            desde: "2020/01/01",
            hasta: "2030/12/31",
            tipo: "c",
            estado: true,
            id_clie: user?.userId
        };
        OrdenDataService.getAll(data, pageSize, page)
            .then(response => {
                setOrders(response.data.data);
                setCount(Math.ceil(response.data.total/pageSize));
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            })

    }, [page]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getFile = (order) => {
        if(order?.ven_id > 0){
            ConfigDataService.getPDF("ve", "Factura"+order?.ven_id, order?.ven_id, true).then(response => {
                const pdfBlob = new Blob([response.data], {type : "application/pdf"});
                const url = window.URL.createObjectURL(pdfBlob);
                window.open(url, '_blank');
            })
            .catch(e => {
                console.log(e);
            })
        }else{
            ConfigDataService.getPDF("c", "Proforma"+order?.tri_numero, order?.tri_id, true).then(response => {
                const pdfBlob = new Blob([response.data], {type : "application/pdf"});
                const url = window.URL.createObjectURL(pdfBlob);
                window.open(url, '_blank');
            })
            .catch(e => {
                console.log(e);
            })
        }
    };


    return (
        <>
            <section className="section">
                <div className='container'>
                    <h2 className='hd'>Pedidos</h2>

                    <div className='table-responsive orderTable'>
                        <table className='table table-striped table-bordered'>
                            <thead className='thead-light'>
                                <tr>
                                    <th>#Pedido</th>
                                    <th>Fecha</th>
                                    <th>Estado</th>
                                    <th>Total</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    orders?.length !== 0 && orders?.map((order, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td><span className='text-blue fonmt-weight-bold'>{order?.tri_numero}</span></td>
                                                    <td>{FormatearFecha(order?.tri_fecha)}</td>
                                                    <td>
                                                        {order?.tri_estado === false ? (
                                                            <span className='badge text-bg-danger'>Anulado</span>
                                                        ) : order?.ven_id > 0 ? (
                                                            <span className='badge text-bg-success'>Facturada</span>
                                                        ) : (
                                                            <span className='badge text-bg-warning'>Pendiente</span>
                                                        )}
                                                    </td>
                                                    <td>{FormatoNumero(order?.tri_total)}</td>
                                                    <td><a href="#" onClick={() => getFile(order)} class="btn-small d-block">Ver</a></td>
                                                </tr>

                                            </>

                                        )
                                    })
                                }

                            </tbody>


                        </table>
                    </div>
                    <div class="row mt-8">
                                <div class="col">

                                    <div className="d-flex aling-items-center justify-content-center mt-5 mb-5">
                                    <Pagination
                                        className="my-3"
                                        count={count}
                                        page={page}
                                        siblingCount={1}
                                        boundaryCount={1}
                                        onChange={handlePageChange}
                                        color="primary"
                                    />
                                    </div>
                                </div>
                            </div>

                </div>
            </section>
        </>
    )
}
export default Orders;