import axios from "axios";

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxIiwianRpIjoiNDVlMzk4ZWQtYmJjOC00ZDkyLWJkOTctYjFjM2VhODg5MTViIiwiaWF0IjoiMTIvMTIvMjAyNCAxNjoyNDoyNCIsInVzcl9pZCI6IjEiLCJlbXBfaWQiOiIxIiwiYmQiOiJlc19kZ18wMiIsImlzcyI6Imh0dHBzOi8vZGluZ29vZWMuY29tLyIsImF1ZCI6Imh0dHBzOi8vZGluZ29vZWMuY29tLyJ9.Z06BapQ8XbhHkJShXYxm0GSD9sqaG-NMhsepInyMkhw';

export default axios.create({
  baseURL: "https://api.dingooec.com/api",
  headers: {
    "Content-type": "application/json",
    'Authorization': 'Bearer ' + token
  }
});