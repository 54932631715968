import logo from '../assets/images/logo.svg';
import { useContext, useEffect, useState } from "react";

import { MyContext } from "../App";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import UserDataService from '../services/user.service'
import CircularProgress from "@mui/material/CircularProgress";

const SignUp = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [formfields, setFormfields] = useState({
        num_documento: "",
        nombres: "",
        apellidos: "",
        email: "",
        password: "",
        tipo_doc: ""
    });
    const context = useContext(MyContext);
    const history = useNavigate();

    useEffect(() => {
        context.setIsHeaderFooterShow(false);
    }, []);

    const onchangeInput = (e) => {
        setFormfields(() => ({
            ...formfields,
            [e.target.name]: e.target.value,
        }));
    };

    const register = (e) => {
        //console.log(formfields);
        e.preventDefault();
        try {
            if (formfields.num_documento === "") {
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: "El campo de documento de identificación no puede quedar en blanco!",
                });
                return false;
            }

            if (formfields.nombres === "") {
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: "El nombre no puede quedar en blanco!",
                });
                return false;
            }

            if (formfields.apellidos === "") {
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: "El apellido no puede quedar en blanco!",
                });
                return false;
            }

            if (formfields.email === "") {
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: "El correo no puede quedar en blanco!",
                });
                return false;
            }

            if (formfields.phone === "") {
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: "El número de telefono no puede quedar en blanco",
                });
                return false;
            }

            if (formfields.password === "") {
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: "El campo de clave no puede quedar en blanco",
                });
                return false;
            }

            if (formfields.password.length < 8) {
                context.setAlertBox({
                    open: true,
                    error: true,
                    msg: "La clave debe tener minimo 8 caracteres",
                });
                return false;
            }

            if(formfields.num_documento.length === 10){
                formfields.tipo_doc = "C";
            }
            else if (formfields.num_documento.length === 13){
                formfields.tipo_doc = "R";
            }
            else{
                formfields.tipo_doc = "P";
            }


            setIsLoading(true);

            UserDataService.Add(formfields)
                .then((res) => {
                    console.log(res);
                    if (res.data.code === 200) {
                        context.setAlertBox({
                            open: true,
                            error: false,
                            msg: "Registrado con éxito!",
                        });

                        setTimeout(() => {
                            setIsLoading(true);
                            history("/signIn");
                        }, 2000);
                    } else {
                        setIsLoading(false);
                        context.setAlertBox({
                            open: true,
                            error: true,
                            msg: res.data.message,
                        });
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error("Error posting data:", error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <section className="section signInPage signUpPage">
            <div className="container">
                <div className="box card p-3 shadow border-0">
                    <div className='text-center'>
                        <img src={logo} width="150px" />
                    </div>
                    <form className='mt-2' onSubmit={register}>
                        <h2 className='mb-3'>Registrarse</h2>
                        <div className='form-group'>
                            <TextField id="standard-basic" label="CI" name='num_documento' onChange={onchangeInput} required variant="standard" className='w-100' />
                        </div>
                        <div className='form-group'>
                            <TextField id="standard-basic" label="Nombres" name='nombres' onChange={onchangeInput} required variant="standard" className='w-100' />
                        </div>
                        <div className='form-group'>
                            <TextField id="standard-basic" label="Apellidos" name='apellidos' onChange={onchangeInput} required variant="standard" className='w-100' />
                        </div>
                        <div className='form-group'>
                            <TextField id="standard-basic" label="Correo" name='email' onChange={onchangeInput} required variant="standard" className='w-100' />
                        </div>
                        <div className='form-group'>
                            <TextField id="standard-basic" label="Contraseña" name='password' onChange={onchangeInput} type='password' required variant="standard" className='w-100' />
                        </div>
                        <Link to="/forgot-password" className='border-effect cursor txt'>Generar / Recuperar la clave?</Link>
                        <div className='d-flex align-items-center mt-3 mb-3 row'>
                            <div className='row w-100'>
                                <div className='col-md-6'>
                                    <Button type="submit" disabled={isLoading === true ? true : false} className='btn-blue btn-lg btn-big w-100'>{isLoading === true ? <CircularProgress /> : "Registrate"}</Button>
                                </div>
                                <div className='col-md-6 pr-0'>
                                    <Link to="/" className='d-block w-100'><Button className='btn-lg btn-big w-100' variant="outlined" onClick={() => context.setIsHeaderFooterShow(true)}>Cancelar</Button></Link>
                                </div>
                            </div>
                        </div>
                        <p>Ya estás registrado? <Link to="/signIn" className='border-effect txt'>Inicia sesión</Link></p>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default SignUp;