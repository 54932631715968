import category_image from '../assets/images/no-user.jpg';
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import ProductoDataService from "../services/product.service"
import { MyContext } from '../App';
import { useState } from 'react';

const Home = () => {

    const context = useContext(MyContext);
    const [banners, setBanners] = useState([]);


    useEffect(() => {
        ProductoDataService.getBanners().then((res) => {
            setBanners(res.data.data)
        })
        .catch(e => {
            console.log(e);
        });
    },[]);

    const settings = {
        //dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };
    return (
        <div className="container mt-3">
            <div className="homeBannerSection">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={15}
                    navigation={false}
                    loop={true}
                    speed={500}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    modules={[Navigation, Autoplay]}
                    className="mySwiper"
                >
                    {banners && banners.map((item, index) => (
                        <SwiperSlide >
                            <div className="item">
                                <Link to={item?.ima_link}><img src={ProductoDataService.getPathImage(encodeURIComponent(item?.ima_direccion))} class="d-block w-100" alt="example" /></Link>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <section class="mb-lg-10 mt-lg-14 my-8">
                <div class="container">
                    <div class="row">
                        <div class="col-12 mb-6">
                            <h3 class="mb-0">Nuestras categorías</h3>
                        </div>
                    </div>
                    <div className="slider-container">
                        <Slider {...settings}>
                            {context.categorys &&
                                context.categorys.map((category, index) => (
                                    <SwiperSlide key={index} class="item">
                                        <Link to={"/cat/" + category.cat_id} class="text-decoration-none text-inherit">
                                            <div className="card  mb-lg-4">
                                                <div className="card-body text-center py-8">
                                                    <img src={ category.imagen === null ? category_image : ProductoDataService.getPathImage(encodeURIComponent(category.imagen?.ima_direccion))} alt="Grocery Ecommerce Template" class="mb-3" />
                                                    <div class="text-truncate">{category.cat_nombre}</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            {/*<div class="item">
                                    <a href="../pages/shop-grid.html" class="text-decoration-none text-inherit">
                                        <div className="card card-product mb-lg-4">
                                            <div className="card-body text-center py-8">
                                                <img src={category_image} alt="Grocery Ecommerce Template" class="mb-3" />
                                                <div class="text-truncate">Dairy, Bread & Eggs</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="item">
                                    <a href="../pages/shop-grid.html" class="text-decoration-none text-inherit">
                                        <div className="card card-product mb-lg-4">
                                            <div className="card-body text-center py-8">
                                                <img src={category_image} alt="Grocery Ecommerce Template" class="mb-3" />
                                                <div class="text-truncate">Dairy, Bread & Eggs</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>*/}
                        </Slider>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;
