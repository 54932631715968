import React, { createContext, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import Header from './components/Header';
import AddTutorial from "./components/add-tutorial.component";
import Tutorial from "./components/tutorial.component";
import TutorialsList from "./components/tutorials-list.component";
import Listing from "./pages/Listing";
import Home from './pages/Home';
import Footer from './components/Footer';

import CategoriaDataService from "./services/category.service"
import ConfigDataService from "./services/config.service"
import ProductDetails from "./pages/ProductDetails";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import SignIn from "./pages/SignIn";
import Search from "./pages/Search";
import SignUp from "./pages/SignUp";
import ResetPass from "./pages/ResetPass";
import Orders from "./pages/Orders";
import NewPass from "./pages/NewPass";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const MyContext = createContext();

function App() {

  const [isHeaderFooterShow, setIsHeaderFooterShow] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [categorys, setCategorys] = useState([]);
  const [dataEmpre, setDataEmpre] = useState({});
  const [configs, setConfigs] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const location = useLocation();

  const [alertBox, setAlertBox] = useState({
    msg: "",
    error: false,
    open: false,
  });

  useEffect(() => {
    retrieveConfigs();
    retrieveCategorys();
    //retrieveBrands();
    
    getCartData();
  }, [])

  useEffect(() => {
    // Cambia la visibilidad del header y footer según la ruta actual
    if (location.pathname === '/signIn' || location.pathname === '/signUp') {
      setIsHeaderFooterShow(false);
    } else {
      setIsHeaderFooterShow(true);
    }
  }, [location]);

  // useEffect(() => {
  //   localStorage.setItem('cart', JSON.stringify(cartData));

  // }, [cartData])

  //OBTENIENDO CATEGORIAS
  const retrieveCategorys = () => {
    CategoriaDataService.getAll("SRP", 0)
      .then(response => {
        setCategorys(response.data.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  //OBTENIENDO MARCAS
  /*const retrieveBrands = () => {
    MarcaDataService.getAll()
      .then(response => {
        setMarcas(response.data.data)
        console.log("Consultando marcas " + response.data.data.length);
        // Inicializar estado de marcas con todas desmarcadas
        const initialBrands = {};
        response.data.data.forEach(brand => {
          initialBrands[brand.mar_id] = false;
        });
        console.log(initialBrands)
        setSelectedBrands(initialBrands);
      })
      .catch(e => {
        console.log(e);
      });
  }*/

  const retrieveConfigs = () => {
    ConfigDataService.getAll("S")
      .then(responde => {
        setDataEmpre(responde.data.data.data_empre);
        setConfigs(responde.data.data.configs)
      })
      .catch(e => {
        console.log(e);
      });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertBox({
      open: false,
    });
  };

  const getCartData = () => {
    setCartData(JSON.parse(localStorage.getItem('cart')) || []);
  }




  const addToCart = (data) => {
    //AGREGANDO O ACTUALIZANDO EN LOCAL
    let storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    const index = storedCart.findIndex((cartItem) => cartItem.pro_id === data.pro_id);
    if (index !== -1) {
      storedCart[index].quantity = storedCart[index].quantity + data.quantity;
      let subtotal = parseFloat(storedCart[index].price * storedCart[index].quantity)
      storedCart[index].subTotal = parseFloat(subtotal - (subtotal * data?.pro_descuento / 100));
      setAlertBox({
        open: true,
        error: false,
        msg: "Ítem actualizado",
      });
    } else {
      storedCart.push(data);
      setAlertBox({
        open: true,
        error: false,
        msg: "Ítem agregado al carrito",
      });
    }
    localStorage.setItem('cart', JSON.stringify(storedCart));
    // LEER DATOS DEL LOCAL
    getCartData();

  }

  const values = {
    categorys,
    setCategorys,
    isHeaderFooterShow,
    setIsHeaderFooterShow,
    configs,
    setConfigs,
    addToCart,
    cartData,
    setCartData,
    getCartData,
    alertBox,
    setAlertBox,
    isLogin, 
    setIsLogin,
    searchData, 
    setSearchData,
    dataEmpre,
    setDataEmpre
  }


  return (
    <>
      <MyContext.Provider value={values}>
        <Snackbar
          open={alertBox.open}
          autoHideDuration={6000}
          onClose={handleClose}
          className="snackbar"
        >
          <Alert
            onClose={handleClose}
            severity={alertBox.error === false ? "success" : "error"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {alertBox.msg}
          </Alert>
        </Snackbar>
        {
          isHeaderFooterShow === true && <Header />
        }
        <Routes>
          {/*<Route path="/" element={<TutorialsList />} />*/}
          <Route path="/" element={<Home />} />
          <Route path="/tutorials" element={<TutorialsList />} />
          <Route path="/add" element={<AddTutorial />} />
          <Route path="/tutorials/:id" element={<Tutorial />} />
          {/*<Route path="/products" element={<ProductsList />} />*/}
          <Route path="/cat/:catId" element={<Listing />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/search" element={<Search />} />
          <Route path="/forgot-password" element={<ResetPass />} />
          <Route exact={true} path="/orders" element={<Orders />} />
          <Route exact={true} path="/forgot-password/resetpass/:token" element={<NewPass />} />
        </Routes>
        {
          isHeaderFooterShow === true && <Footer />
        }
      </MyContext.Provider>

    </>
  );
}
export default App;

export { MyContext };
