import { format } from "date-fns";


export const FormatoNumero = (numero) => {
  const numeroFormateado = numero.toLocaleString('es-ES', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return numeroFormateado;
};

export const FormatearFecha = (fecha) => {
  const fechaFormateada = format(new Date(fecha), "dd/MM/yyyy HH:mm");

  return fechaFormateada;
};