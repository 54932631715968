import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MyContext } from "../App";
import MarcaDataService from "../services/brand.service";

const Sidebar = (props) => {

    const context = useContext(MyContext);
    const history = useNavigate();

    const [subCatId, setSubCatId] = useState('');
    const [marcas, setMarcas] = useState([]);
    const { catId } = useParams();
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedBrandsVal, setSelectedBrandsVal] = useState([]);

    useEffect(() => {
        setSubCatId(catId);
        retrieveBrands(catId);
    }, [catId])

    // Fetch products when selectedBrands change
    useEffect(() => {
        if (selectedBrands.length > 0) {
            //fetchProducts(selectedBrands);
            props.filterByMarca(selectedBrands, subCatId);
        } else {
            if (subCatId !== "") {
                //setProducts([]); // Clear products if no brands selected
                props.filterData(subCatId);
            }

        }
    }, [selectedBrands]);

    const retrieveBrands = (catId) => {
        if(catId === undefined){
            catId = 0;
        }
        MarcaDataService.getAll(catId)
            .then(response => {
                setMarcas(response.data.data)
                //console.log("Consultando marcas " + response.data.data.length);
                // Inicializar estado de marcas con todas desmarcadas
                if(response.data.data !== null){
                    const initialBrands = {};
                    response.data.data.forEach(brand => {
                        initialBrands[brand.mar_id] = false;
                    });
                    setSelectedBrandsVal(initialBrands);
                }
                
            })
            .catch(e => {
                console.log(e);
            });
    }

    const handleCategoryClick = (categoryId) => {
        //props.filterData(categoryId);
        desmarcarChecks();
        retrieveBrands(categoryId);
        setSubCatId(categoryId);
        setSelectedBrands([]);
        history("/cat/" +categoryId);
    };

    const desmarcarChecks = () => {
        const resetBrands = {};
        if(marcas !== null){
            marcas.forEach(brand => {
                resetBrands[brand.mar_id] = false;
            });
        }
        setSelectedBrandsVal(resetBrands);
    };

    const handleCheckboxChange = (brandId) => {
        setSelectedBrands((prevSelected) =>
            prevSelected.includes(brandId)
                ? prevSelected.filter((b) => b !== brandId)  // Uncheck
                : [...prevSelected, brandId]                // Check
        );
        // Manejar cambios en los checkboxes de marcas
        setSelectedBrandsVal(prevState => ({
            ...prevState,
            [brandId]: !prevState[brandId]
        }));

    };

    return (
        <>
            <div class="sidebar offcanvas-body ps-lg-2 pt-lg-0">
                <div class="filterBox">

                    <h5 class="mb-3">Categorías</h5>

                    <ul class="nav nav-category" id="categoryCollapseMenu">

                        {context.categorys &&
                            context.categorys.map((category, index) => (
                                <li key={index} class="nav-item border-bottom w-100">
                                    <Link to={"#"} className="nav-link collapsed" onClick={() => handleCategoryClick(category.cat_id)} data-bs-toggle="collapse" data-bs-target={"#categoryFlush" + category.cat_id} aria-expanded="false" aria-controls="categoryFlushOne">
                                        {category.cat_nombre}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
                                    </Link>

                                    <div id={"categoryFlush" + category.cat_id} class="accordion-collapse collapse" data-bs-parent="#categoryCollapseMenu">
                                        <div>
                                            <ul class="nav flex-column ms-3">
                                                {category.subcategorias &&
                                                    category.subcategorias.map((subcategory, indexSub) => (
                                                        <li key={indexSub} class="nav-item"  ><Link to={"/cat/" + subcategory.cat_id} className="nav-link" onClick={() => handleCategoryClick(subcategory.cat_id)}>{subcategory.cat_nombre}</Link>{/*<a href="#!" class="nav-link">{subcategory.cat_nombre}</a>*/}</li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
                {
                    marcas && marcas.length > 1 && (
                        <div class="filterBox">
                            <h5 class="mb-3">Marcas</h5>
                            <div className='scroll'>
                                <ul>
                                    {marcas &&
                                        marcas.map((marca, index) => (
                                            <li key={index}>
                                                <FormControlLabel className='w-100' control={<Checkbox checked={selectedBrandsVal[marca.mar_id]} onChange={() => handleCheckboxChange(marca.mar_id)} />} label={marca.mar_nombre} />
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    )
                }

                {/*<div class="mb-8">

                                            <h5 class="mb-3">Price</h5>
                                            <div>

                                                <div id="priceRange" class="mb-3"></div>
                                                <small class="text-muted">Price:</small>
                                                <span id="priceRange-value" class="small"></span>
                                            </div>
                                        </div>*/}

            </div>
        </>
    )
}
export default Sidebar;