import http from "../http-common";

class ProductoDataService {
    /*getAll() {
      return http.get("/productos");
    }*/
    getAll(data, pageSize, page) {
      return http.post(`/producto/listado/${pageSize}/${(page - 1)*pageSize}`, data);
    }

    getPathImage(ruta) {
      return http.defaults.baseURL+"/producto/imagen/" + ruta;
    }

    async getById(id) {
      return await http.post(`/producto/search/${id}`);
    }

    getBanners() {
      return http.get("/producto/bantv");
    }
}

export default new ProductoDataService();