import { useContext, useEffect, useState } from "react";
import ProductZoom from "../components/ProductZoom";
import QuantityBox from "../components/QuantityBox";
import Button from "@mui/material/Button"
import { BsCartFill } from "react-icons/bs";
import { FaRegHeart } from "react-icons/fa6";
import { Tooltip } from "@mui/material";
import ProductoDataService from "../services/product.service"
import { useParams } from "react-router-dom";
import { FormatoNumero } from "../utils/Utility";
import { MyContext } from "../App";
import CircularProgress from "@mui/material/CircularProgress";

const ProductDetails = () => {

    const [productData, setProductData] = useState({});
    const [activeSize, setActiveSize] = useState(null);
    const [precio, setPrecio] = useState(0);
    const [isLoading, setisLoading] = useState(false);
    let [cartFields, setCartFields] = useState({});
    let [productQuantity, setProductQuantity] = useState();
    const [isDisabled, setIsDisabled] = useState(true);

    const context = useContext(MyContext);

    const isActive = (index) => {
        setActiveSize(index);
    };

    const { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        setisLoading(true);
        ProductoDataService.getById(id).then((response) => {
            setProductData(response.data.data);
            setPrecio(response.data.data?.precio.prs_precio);
            setIsDisabled(response.data.data?.countinstock > 1);
            setisLoading(false);
        })
            .catch(e => {
                console.log(e);
                setisLoading(false);
            });
    }, [id]);

    const quantity = (val) => {
        setProductQuantity(val);
    };

    const addtoCart = () => {
        if (activeSize == null) {
            cartFields.pro_nombre = productData?.pro_nombre;
            cartFields.image = productData?.imagenes[0];
            cartFields.price = productData?.precio?.prs_precio;
            cartFields.pro_descuento = productData?.pro_descuento;
            cartFields.quantity = productQuantity;
            let subtotal = parseFloat(productData?.precio?.prs_precio * productQuantity)
            cartFields.subTotal = parseFloat(subtotal - (subtotal * productData?.pro_descuento / 100));
            cartFields.pro_id = productData?.pro_id;
            cartFields.countinstock = productData?.countinstock;

            context.addToCart(cartFields);
        } else {
            //setTabError(true);
        }
    };

    const selectedItem = () => { };

    return (
        <>
            <section className="productDetails section">
                <div className="container">
                    {isLoading === true ? (
                        <div className="loading d-flex align-items-center justify-content-center">
                            <CircularProgress color="inherit" />
                        </div>
                    ) : (
                        <>
                            <div className="row">
                                <div className="col-md-4 pl-5">
                                    <ProductZoom images={productData?.imagenes} pro_descuento={productData?.pro_descuento} />
                                </div>
                                <div className="col-md-7 pl-5 pr-5">
                                    <h2 className="hd text-capitalize">{productData?.pro_nombre}</h2>
                                    <ul className="list list-inline d-flex align-items-center">
                                        <li className="list-inline-item">
                                            <div className="d-flex align-items-center">
                                                <span className="text-secondary mr-2">Marca: </span>
                                                <span>{productData?.marca?.mar_nombre}</span>
                                            </div>
                                        </li>
                                        <li className="list-inline-item ">
                                            <div className="d-flex align-items-center">
                                                <span className="text-secondary mr-2">Código: </span>
                                                <span>{productData?.pro_codigo}</span>
                                            </div>
                                        </li>
                                    </ul>

                                    <div className="d-flex info mb-3">
                                        <span className="newPrice text-success">${FormatoNumero(precio - (precio * (productData?.pro_descuento / 100)))}</span>
                                        {productData?.pro_descuento > 0 && (
                                            <span className="oldPrice">${FormatoNumero(precio)}</span>
                                        )}

                                    </div>
                                    {productData?.countinstock > 0 ? (
                                        <span className="badge bg-success">Disponible</span>
                                    ) : (
                                        <span className="badge bg-danger">Agotado</span>
                                    )}

                                    {/*<p className="mt-3">
                                {productData?.pro_observacion}
                            </p>*/}

                                    {/*<div className="productSize d-flex align-items-center">
                                <span>Tamaño/Peso:</span>
                                <ul className="list-inline mb-0 pl-4">
                                    <li className="list-inline-item"><a className={`tag ${activeSize === 0 ? 'active' : ''}`}
                                        onClick={() => isActive(0)}>50g</a></li>
                                    <li className="list-inline-item"><a className={`tag ${activeSize === 1 ? 'active' : ''}`}
                                        onClick={() => isActive(1)}>40g</a></li>
                                    <li className="list-inline-item"><a className={`tag ${activeSize === 2 ? 'active' : ''}`}
                                        onClick={() => isActive(2)}>30g</a></li>
                                </ul>
                            </div>*/}

                                    <div className="d-flex align-items-center mt-3 actions_">
                                        {productData?.countinstock > 1 && (
                                            <>
                                                <QuantityBox
                                                    quantity={quantity}
                                                    item={productData}
                                                    selectedItem={selectedItem}
                                                />
                                            </>
                                        )}
                                        <div class="d-flex align-items-center btnActions">
                                            <Button className="btn-blue btn-lg btn-big btn-round" disabled={!isDisabled} onClick={() => addtoCart()} >{isDisabled ? (
                                                <>
                                                    <BsCartFill />&nbsp; Agregar
                                                </>
                                            ) : (
                                                "NO DISPONIBLE"
                                            )}</Button>
                                            <Tooltip title="Agregar a favoritos" placement="top">
                                                <Button className="btn-blue btn-lg btn-big btn-circle ml-4"><FaRegHeart /></Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-lg-14 mt-8">
                                <div class="col-md-12">
                                    <ul class="nav nav-pills nav-lb-tab" id="myTab" role="tablist">

                                        <li class="nav-item" role="presentation">

                                            <button class="btnTab nav-link active" id="product-tab" data-bs-toggle="tab" data-bs-target="#product-tab-pane" type="button" role="tab" aria-controls="product-tab-pane" aria-selected="true">
                                                Descripción
                                            </button>
                                        </li>

                                        <li class="nav-item" role="presentation">

                                            <button class="btnTab nav-link" id="details-tab" data-bs-toggle="tab" data-bs-target="#details-tab-pane" type="button" role="tab" aria-controls="details-tab-pane" aria-selected="false" tabindex="-1">
                                                Información adicional
                                            </button>
                                        </li>
                                    </ul>

                                    <div class="tab-content" id="myTabContent">

                                        <div class="tab-pane fade active show" id="product-tab-pane" role="tabpanel" aria-labelledby="product-tab" tabindex="0">
                                            <div class="my-8">
                                                <div class="mbt-5">
                                                    {/*<h4 class="mb-1">Nutrient Value &amp; Benefits</h4>*/}
                                                    <p class="mb-0">
                                                        {productData?.pro_observacion}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="details-tab-pane" role="tabpanel" aria-labelledby="details-tab" tabindex="0">
                                            <div class="my-8">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h4 class="mb-4">Detalles</h4>
                                                    </div>
                                                    <div class="col-12 col-lg-6">
                                                        <table class="table table-striped">

                                                            <tbody>
                                                                {productData.detalleinformacion && productData.detalleinformacion.map((infor, index) => (
                                                                    <tr>
                                                                        <th>{infor.dep_nombre}</th>
                                                                        <td>{infor.ina_informacion}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </section>
        </>
    )
}

export default ProductDetails;