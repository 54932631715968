import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import Button from '@mui/material/Button';
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../App";

const QuantityBox = (props) => {
    const [inputVal, setInputVal] = useState(1);

    const context = useContext(MyContext);

    useEffect(() => {
        //VERIFICANDO CANTIDAD PARA ACTUALIZAR
        if (props?.value !== undefined && props?.value !== null && props?.value !== "") {
            setInputVal(parseFloat(props?.value));
        }
    }, [props.value])

    const minus = () => {
        if (inputVal === '') {
            setInputVal(1);
        } else if (inputVal !== 1 && inputVal > 0) {
            setInputVal(Number(inputVal) - 1);
        }
    }

    const plus = () => {
        let stock = parseFloat(props.item.countinstock);
        if (inputVal === '') {
            setInputVal(1);
        } else {
            if(inputVal < stock){
                setInputVal(Number(inputVal) + 1);
            }else{
                context.setAlertBox({
                    open:true,
                    error:true,
                    msg:"La cantidad es mayor que el número de productos en stock."
                })
            }
        }
    }

    useEffect(() => {
        if (props.quantity) {
            props.quantity(inputVal);
        }

        if (props.selectedItem) {
            props.selectedItem(props.item, inputVal);
        }

    }, [inputVal]);

    const handleChange = (event) => {
        setInputVal(event.target.value);
    }

    return (
        <div className="quantityDrop d-flex align-items-center">
            <Button onClick={minus}><FaMinus /></Button>
            <input type="text" value={inputVal} onChange={handleChange} />
            <Button onClick={plus}><FaPlus /></Button>
        </div>
    )
}

export default QuantityBox;