import { Button } from "@mui/material";
import { IoMdHeartEmpty } from "react-icons/io";
import { MyContext } from "../App";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ProductoDataService from "../services/product.service"
import { FormatoNumero } from "../utils/Utility"
import { BsCartFill } from "react-icons/bs";

const ProductItem = (props) => {

    const context = useContext(MyContext);
    const pathImage = context?.configs?.find(c => c.con_id === 35);
    const basePath = pathImage ? pathImage.con_detalle : '';
    const pathCode = encodeURIComponent(`${basePath}Products\\${props.item.imagen}`);
    const imageUrl = ProductoDataService.getPathImage(`${pathCode}`);
    const [isDisabled, setIsDisabled] = useState(props.item?.countinstock > 1);

    const addtoCart = () => {
        let cartFields = {};
        cartFields.pro_nombre = props.item?.Producto;
        cartFields.image = {
            ima_id : 0,
            ima_direccion : `${basePath}Products\\${props.item.imagen}`
        };
        cartFields.price = props.item?.pvp1;
        cartFields.pro_descuento = props.item?.pro_descuento;
        cartFields.quantity = 1;
        let subtotal = parseFloat(props.item?.pvp1 * 1)
        cartFields.subTotal = parseFloat(subtotal - (subtotal * props.item?.pro_descuento / 100));
        cartFields.pro_id = props.item?.pro_id;
        cartFields.countinstock = props.item?.countinstock;
        context.addToCart(cartFields);
    };

    return (
        <div className="productItem">
            <div className="imgWrapper">
                <Link to={"/product/" + props.item.pro_id}>
                    <img src={imageUrl} alt={props.item.pro_nombre} className="w-100" />
                </Link>
                {props.item.pro_descuento > 0 && (
                    <span className="badge badge-primary">{props.item.pro_descuento}%</span>
                )}
                <div className="actions">
                    <Button><IoMdHeartEmpty style={{ fontSize: '20px' }} /></Button>
                </div>
            </div>
            <div className="info">
                <Link to={"/product/" + props.item.pro_id}><h4>{props.item.Producto}</h4></Link>
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <div>
                        {props.item?.countinstock > 1 && (
                            <>
                            <span className="newPrice">${FormatoNumero(props.item?.precio - (props.item?.precio * (props?.item?.pro_descuento / 100)))}</span>
                            {props?.item?.pro_descuento > 0 && (
                                <span className="oldPrice text-decoration-line-through text-muted-style">${FormatoNumero(props.item?.precio)}</span>
                            )}
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <Button className="btn-blue btn-lg btn-round" disabled={!isDisabled} onClick={() => addtoCart()}>{isDisabled ? (
                        <>
                        <BsCartFill />&nbsp; Agregar
                        </>
                    ) : (
                        "NO DISPONIBLE"
                    )}</Button>
                </div>
            </div>
        </div>
    )
}
export default ProductItem;