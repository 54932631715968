import logo from '../assets/images/logo.svg';
import { Link, useNavigate } from "react-router-dom";
import SearchBox from './SearchBox';
import { FiUser } from "react-icons/fi";
import { Button } from '@mui/material';
import { IoBagOutline } from 'react-icons/io5';
import { useContext, useState } from 'react';
import { MyContext } from '../App';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FaUserAlt } from "react-icons/fa";
import { FaClipboardCheck } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { IoMdMenu } from 'react-icons/io';
import ProductoDataService from "../services/product.service"

const Header = () => {

  const [anchorEl, setAnchorEl] = useState(null);
  const context = useContext(MyContext);
  const open = Boolean(anchorEl);

  const pathImage = context?.configs?.find(c => c.con_id === 35);
  const basePath = pathImage ? pathImage.con_detalle : '';
  const pathCode = encodeURIComponent(`${basePath}DINGOO\\${context?.dataEmpre.imagen}`);
  const imageUrl = ProductoDataService.getPathImage(`${pathCode}`);

  const history = useNavigate();




  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAnchorEl(null);
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    // localStorage.removeItem("location");
    context.setIsLogin(false);
    // window.location.href = "/signIn"
    history("/signIn");
  }
  return (
    <header className="header">
      <div class="navbar-expand-lg bg-body-tertiary walsh-float-menu" >
        <div class="container">
          <div class="row">
            <div class="logoWrapper d-flex align-items-center col-sm-2">
            <button data-mdb-collapse-init="" class="navbar-toggler collapsed" type="button" data-mdb-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <IoMdMenu size={28} />
          </button>
              <Link to={"/"} className="logo">
                <img src={imageUrl} alt="logo" width="150px" height="35" />
              </Link>
            </div>
            <div class="col-sm-10 d-flex align-items-center part2">
              <div class="headerSearchWrapper false">
                <div class=" d-flex align-items-center">
                  <div class="headerSearch">
                    <SearchBox />
                  </div>
                </div>
              </div>
              <div class="part3 d-flex align-items-center ml-auto">
                <div class="list-inline-item me-3">
                  {
                    context.isLogin !== true && <Link to="/signIn">
                      <Button className="btn-blue btn-round mr-3">Acceder</Button>
                    </Link>
                  }
                  {
                    context.isLogin === true &&
                    <>
                      <Button className="circle" onClick={handleClick}><FiUser /></Button>
                      <Menu
                        anchorEl={anchorEl}
                        id="accDrop"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        {/*<Link to="/my-account">
                      <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                          <FaUserAlt fontSize="small" />
                        </ListItemIcon>
                        Mi cuenta
                      </MenuItem>
                    </Link>*/}
                        <Link to="/orders">
                          <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                              <FaClipboardCheck fontSize="small" />
                            </ListItemIcon>
                            Ordenes
                          </MenuItem>
                        </Link>
                        {/*<Link to="/my-list">
                      <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                          <FaHeart fontSize="small" />
                        </ListItemIcon>
                        Mis favoritos
                      </MenuItem>
                    </Link>*/}
                        <MenuItem onClick={logout}>
                          <ListItemIcon>
                            <RiLogoutCircleRFill fontSize="small" />
                          </ListItemIcon>
                          Cerrar sesión
                        </MenuItem>
                      </Menu>
                    </>
                  }
                </div>
                {/* Icon */}
                <div class="list-inline-item">
                  <div className='position-relative'>
                    <Link to="/cart">
                      <Button className='circle'><IoBagOutline /></Button>
                      <span className='count d-flex align-items-center justify-content-center'>{context.cartData?.length > 0 ? context.cartData?.length : 0}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header;