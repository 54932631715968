import logo from '../assets/images/logo.svg';
import { useContext, useEffect, useState } from "react";

import { MyContext } from "../App";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import UserDataService from '../services/user.service'
import CircularProgress from "@mui/material/CircularProgress";


const SignIn = () => {
    
    const [isLoading, setIsLoading] = useState(false);
    const context = useContext(MyContext);
    const history = useNavigate();

    useEffect(() => {
        context.setIsHeaderFooterShow(false);
    }, []);

    const [formfields, setFormfields] = useState({
        usuario: "",
        password: "",
    });


    const login = (e) => {
        e.preventDefault();

        if (formfields.usuario === "") {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "El usuario no puede quedar en blanco!",
            });
            return false;
        }

        if (formfields.password === "") {
            context.setAlertBox({
                open: true,
                error: true,
                msg: "La contraseña no puede quedar en blanco!",
            });
            return false;
        }

        setIsLoading(true);

        UserDataService.getData(formfields).then(res => {
            try {
                if (res.data.code === 200) {
                    localStorage.setItem("authToken", res.data.token);
          
                    const user = {
                      name: res.data.data?.per_apellidos + " " + res.data.data?.per_nombres,
                      email: res.data.data?.per_email,
                      userId: res.data.data?.clie_id,
                    };
          
                    localStorage.setItem("user", JSON.stringify(user));
          
                    context.setAlertBox({
                      open: true,
                      error: false,
                      msg: res.data.message,
                    });
          
                    setTimeout(() => {
                      history("/");
                      context.setIsLogin(true);
                      setIsLoading(false);
                      context.setIsHeaderFooterShow(true);
                      //window.location.href = "/";
                    }, 2000);
                  } else {
                    context.setAlertBox({
                      open: true,
                      error: true,
                      msg: res.data.message,
                    });
                    setIsLoading(false);
                  }
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        });
    };

    const onchangeInput = (e) => {
        setFormfields(() => ({
            ...formfields,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <section className="section signInPage">
            <div className="container">
                <div className="box card p-3 shadow border-0">
                    <div className='text-center'>
                        <img src={logo} width="150px" />
                    </div>
                    <form className='mt-3' onSubmit={login}>
                        <h2 className='mb-4'>Iniciar sesión</h2>
                        <div className='form-group'>
                            <TextField label="Usuario" required variant="standard" className='w-100' name='usuario' onChange={onchangeInput} />
                        </div>
                        <div className='form-group'>
                            <TextField label="Contraseña" type='password' required variant="standard" className='w-100' name='password' onChange={onchangeInput} />
                        </div>
                        <Link to="/forgot-password" className='border-effect cursor txt'>Generar / Recuperar la clave?</Link>
                        <div className='d-flex align-items-center mt-3 mb-3'>
                            <Button type="submit" className='btn-blue btn-lg btn-big col'>{isLoading === true ? <CircularProgress /> : "Ingresar"}</Button>
                            <Link to="/"><Button className='btn-lg btn-big col ml-3' variant="outlined" onClick={() => context.setIsHeaderFooterShow(true)}>Cancelar</Button></Link>
                        </div>
                        <p>No estás registrado? <Link to="/signUp" className='border-effect txt'>Registrate</Link></p>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default SignIn;