import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import UserDataService from '../services/user.service'
import { useContext, useState } from "react";
import { MyContext } from "../App";
import CircularProgress from "@mui/material/CircularProgress";

const ResetPass = () => {
   const [email, setEmail] = useState("");
   const context = useContext(MyContext);
   const [isLoading, setIsLoading] = useState(false);
   const [isSuccessful, setIsSuccessful] = useState(false);

   const handleSubmit = (e) => {
      e.preventDefault();
      var data = {
         email: email,
         host: window.location.href
      };
      setIsLoading(true);
      UserDataService.getRestore(data).then(response => {
         if (response.data.code === 200) {
            context.setAlertBox({
               open: true,
               error: false,
               msg: "Contraseña reseteada con éxito",
            });
            setTimeout(() => {
               setIsSuccessful(true);
               //history("/signIn");
               setIsLoading(false);
            }, 2000);
         } else {
            setIsLoading(false);
            context.setAlertBox({
               open: true,
               error: true,
               msg: response.data.message,
            });
         }
      })
         .catch(ex => {
            setIsLoading(false);
            console.log(ex);
         });
   }

   return (
      <main>
         <section class=" my-8 my-lg-14">
            <div class="container">
               <div class="row justify-content-center align-items-center">
                  <div class="col-xl-4 col-lg-6 col-md-12 m-auto">
                     <div>
                        {isSuccessful === false ? (
                           <>
                              <div class="mb-lg-9 mb-5">
                                 <h1 class="mb-2 h2 fw-bold">¿Olvidaste tu contraseña?</h1>
                                 <p>Ingrese la dirección de correo electrónico asociada a su cuenta y le enviaremos un enlace para restablecer su contraseña.</p>
                              </div>
                              <form class="needs-validation" novalidate onSubmit={handleSubmit}>
                                 <div class="row g-3">
                                    <div class="col-12">
                                       <label for="formForgetEmail" class="form-label visually-hidden">Email address</label>
                                       <input type="email" class="form-control" id="formForgetEmail" placeholder="Email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                       <div class="invalid-feedback">Please enter correct password.</div>
                                    </div>
                                    <div class="col-12 d-grid gap-2">
                                       <Button type="submit" class="btn btn-primary btntv">{isLoading === true ? <CircularProgress /> : "Restablecer contraseña"}</Button>
                                       <Link to={"/signIn"} class="btn btn-light">Atrás</Link>
                                    </div>
                                 </div>
                              </form>
                           </>

                        ) : (
                           <div class="mb-lg-9 mb-5">
                              <h1 class="mb-2 h3 fw-bold" style={{ textAlign: 'center' }}>Proceso de recuperación</h1><br />
                              <p>El proceso de recuperación de clave de acceso ha iniciado, por favor revise su correo electrónico.</p><br />
                              <div class="col-12 d-grid gap-2">
                                 <Link to={"/signIn"} class="btn btn-light">Atrás</Link>
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </main>
   )

}

export default ResetPass;