import http from "../http-common";

class OrdenDataService{
    save(data) {
        return http.post(`/transaccioninterna/orden`, data);
    }

    getAll(data, pageSize, page){
        return http.post(`/transaccioninterna/listado/XF/${pageSize}/${(page - 1)*pageSize}`, data);
    }
}

export default new OrdenDataService();