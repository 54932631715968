import http from "../http-common"

class ConfigDataService{

    getAll(opcion) {
        return http.get(`/sistema/parametrostv/${opcion}`);
    }

    getProvinciasCantones(){
        return http.get("/sistema/prov_cant");
    }

    getPDF(tipo, archivo, id, electronico){
        return http.get(`/sistema/pdf/${tipo}/${archivo}/${id}/${electronico}`, {
            responseType: 'blob'
        });
    }
}

export default new ConfigDataService();