import React, { useState, useEffect } from "react";
import ProductoDataService from "../services/product.service"
import { useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Pagination from '@mui/material/Pagination';
//import Stack from '@mui/material/Stack';
import ProductItem from "../components/ProductItem";
import CircularProgress from "@mui/material/CircularProgress";

const Listing = () => {

    const [productData, setProductData] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const { catId } = useParams();
    const [categoryId, setCategoryId] = useState(catId);
    const [brandIds, setBrandIds] = useState([]);

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(12);

    const pageSizes = [12, 24, 48];

    useEffect(() => {window.scrollTo(0, 0); retrieveProducts(categoryId);}, [page, pageSize, categoryId, brandIds]);

    // OBTENIENDO PRODUCTOS
    const retrieveProducts = (_categoryId) => {
        var data = {
            tipo: "SCDTV",
            busqueda: "",
            id_bod_origen: 1,
            cat_id: _categoryId,
            mar_ids: brandIds,
        };
        setisLoading(true);
        ProductoDataService.getAll(data, pageSize, page)
            .then(response => {
                setProductData(response.data.data);
                setCount(Math.ceil(response.data.total/pageSize));
                setisLoading(false);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const filterData = (subCatId) => {
        setBrandIds([]);
        setCategoryId(subCatId);
        setPage(1);
    };

    const filterByMarca = (marIds, subCatId) => {
        setBrandIds(marIds);
        setCategoryId(subCatId);
        setPage(1);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <main>
            {
                /*<div class="mt-4">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item"><a href="#!">Home</a></li>
                                    <li class="breadcrumb-item"><a href="#!">Shop</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Snacks & Munchies</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                </div>*/
            }
            
            {/*<!-- section -->*/}
            <div class="mt-8 mb-lg-14 mb-8">
                <div class="container">
                    <div class="row gx-10">
                        <aside class="col-lg-3 col-md-4 mb-6 mb-md-0">
                            <div class="offcanvas offcanvas-start offcanvas-collapse w-md-50" tabindex="-1" id="offcanvasCategory" aria-labelledby="offcanvasCategoryLabel">
                                <div class="offcanvas-header d-lg-none">
                                    <h5 class="offcanvas-title" id="offcanvasCategoryLabel">Filter</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <Sidebar filterData={filterData} filterByMarca={filterByMarca} />
                            </div>
                        </aside>
                        <section class="col-lg-9 col-md-12">

                            {/*<div class="card mb-4 bg-light border-0">

                                <div class="card-body p-9">
                                    <h2 class="mb-0 fs-1">Snacks & Munchies</h2>
                                </div>
                            </div>*/}

                            <div class="d-lg-flex justify-content-between align-items-center">
                                <div class="mb-3 mb-lg-0">
                                    <p class="mb-0"><span class="text-dark">{productData ? productData.length : 0}</span> Productos encontrados </p>
                                </div>


                                <div class="d-md-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="ms-2 d-lg-none">
                                            <a class="btn btn-outline-gray-400 text-muted" data-bs-toggle="offcanvas" href="#offcanvasCategory" role="button" aria-controls="offcanvasCategory">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-filter me-2">
                                                    <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                                                </svg>
                                                Filtros
                                            </a>
                                        </div>
                                    </div>

                                    <div class="d-flex mt-2 mt-lg-0">
                                        <div class="me-2 flex-grow-1">
                                            <select onChange={handlePageSizeChange} value={pageSize} class="form-select">
                                            {pageSizes.map((size) => (
                                                <option key={size} value={size}>
                                                    {size}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                        {/*<div>
                                            <select class="form-select">
                                                <option selected>Ordenar por: Destacados</option>
                                                <option value="Low to High">Precio: de menor a mayor</option>
                                                <option value="High to Low">Precio: de mayor a menor</option>
                                                <option value="Release Date">Fecha de lanzamiento</option>
                                            </select>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>


                            <div class="container_pro">
                                {isLoading === true ? (
                                    <div className="loading d-flex align-items-center justify-content-center">
                                        <CircularProgress color="inherit" />
                                    </div>
                                ) : (
                                    <>
                                        {productData &&
                                        productData.map((producto, index) => (
                                            <ProductItem key = {index} item={producto}/>
                                        ))}
                                    </>
                                )}
                            </div>

                            <div class="row mt-8">
                                <div class="col">

                                    <div className="d-flex aling-items-center justify-content-center mt-5 mb-5">
                                    <Pagination
                                        className="my-3"
                                        count={count}
                                        page={page}
                                        siblingCount={1}
                                        boundaryCount={1}
                                        onChange={handlePageChange}
                                        color="primary"
                                    />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {/**************************** */}
            <div className="col-12">

            </div>
        </main>
    );
}
export default Listing;