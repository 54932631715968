import { useEffect } from 'react';

const ProcessPay = (props) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${props.checkId}`;
    script.async = true;
    document.body.appendChild(script);
    console.log(props.checkId);
    // Limpieza: eliminar el script cuando el componente se desmonte
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {console.log(`https://test.oppwa.com/v1/checkouts/${props.checkId}/payment`)}
      <form action={`https://test.oppwa.com/v1/checkouts/${props.checkId}/payment`} method='POST' lang='es' class='paymentWidgets' data-brands='VISA MASTER AMEX DISCOVER'></form>
    </div>
  );
}
export default ProcessPay;