import { useContext, useState } from "react";
import ProductoDataService from "../services/product.service";
import { MyContext } from '../App';
import { useNavigate } from "react-router-dom";

const SearchBox = () => {

    const [searchFields, setSearchFields] = useState("");

    const onChangeValue = (e) => {
        setSearchFields(e.target.value);
    }

    const context = useContext(MyContext);
    const history = useNavigate();

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            searchProducts();
        }
    }

    const searchProducts = () => {
        var data = {
            tipo: "SCDTV",
            busqueda: searchFields,
            id_bod_origen: 1,
            estado: true,
            cat_id: -2,
            mar_id: 0,
        };

        ProductoDataService.getAll(data, 8, 1)
            .then(response => {
                context.setSearchData(response.data.data);
                history("/search");
            })
            .catch(e => {
                console.log(e);
            });
    }

    return (
        <div class="input-group">
            <input class="form-control rounded" type="search" placeholder="Búsqueda de productos..." onChange={onChangeValue} onKeyUp={handleKeyUp} />
            <span class="input-group-append">
                <button class="btn bg-white border border-start-0 ms-n10 rounded-0 rounded-end" type="button">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                </button>
            </span>
        </div>
    )
}

export default SearchBox;